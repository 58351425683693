<template>
  <div id="divSmsVerificationWrapper">
    <VueForm ref="form">
      <VueText sizeLevel="11" weightLevel="3" class="header" color="grey-40">SMS Doğrulama</VueText>
      <VueText sizeLevel="9" weightLevel="3" class="phone-number"
        >+90 {{ this.formModel.phoneNumber }}</VueText
      >
      <VueText class="verification-code-info" sizeLevel="3"
        >Numaralı telefona gönderilen doğrulama kodunu aşağıdaki alana yazınız.</VueText
      >
      <VueText sizeLevel="5" weightLevel="3" class="form-text" color="grey-40"
        >SMS Doğrulama Kodu</VueText
      >
      <VueInput
        name="verificationCode"
        id="verificationCode"
        type="password"
        validatorName="SMS Kodu"
        v-model="formModel.verificationCode"
        :messageOnTop="true"
        :inputLarge="true"
        validationRules="required|numeric|max:7|min:6"
      />
      <div class="sms-button-wrapper">
        <div
          v-if="timer.countdown > 0 && isRepeatButtonDisabled"
          :class="[
            isRepeatButtonDisabled ? 'disabled-sms-button-countdown' : 'sms-button-countdown',
          ]"
        >
          {{ timer.countdown }}
        </div>
        <BrandButton
          :outlined="true"
          :size="sizes.xSmall"
          :contentAlignment="constants.flexAlignment.end"
          :disabled="isRepeatButtonDisabled"
          class="brand-repeat-button"
          :class="[isRepeatButtonDisabled ? 'brand-repeat-button-disabled' : '']"
          @click="sendSmsCodeAgain"
        >
          <VueText sizeLevel="3" weightLevel="2">Tekrar Gönder</VueText>
        </BrandButton>
      </div>
      <div slot="footer" class="button-holder">
        <BrandButton
          class="submit-button"
          :size="sizes.xxLarge"
          :contentAlignment="constants.flexAlignment.center"
          type="submit"
          :padding="{ vertical: 15, horizontal: 0 }"
          @click.prevent="handleSubmit"
          >DEVAM ET</BrandButton
        >
      </div>
    </VueForm>
    <BrandAuthFormFooter></BrandAuthFormFooter>
  </div>
</template>

<script>
import BrandAuthFormFooter from '@/components/brand/BrandAuthFormFooter/BrandAuthFormFooter.vue';
import RoutesAthentication from '@/router/routes/RoutesAthentication.js';
import RoutesLogin from '@/router/routes/authentication/RoutesLogin.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import { Account } from '@/services/Api/index';
import StorageHelper from '@/utils/storageHelper';

export default {
  name: 'SmsVerification',
  components: {
    BrandAuthFormFooter,
    VueForm,
    VueInput,
    BrandButton,
    VueText,
  },
  data() {
    let smsCounter = new StorageHelper({
      id: process.env.VUE_APP_STORAGE_FIRST_LOGIN_SMS_COUNTER,
    }).get();
    const counterConfig = 180;
    let counter = counterConfig;
    let startCountDownImmediate = true;
    let repeatDisabled = true;
    if (!smsCounter) {
      startCountDownImmediate = false;
      repeatDisabled = false;
      new StorageHelper({ id: process.env.VUE_APP_STORAGE_FIRST_LOGIN_SMS_COUNTER }).set({
        date: new Date(),
        isRefresh: true,
      });
    } else {
      if (smsCounter.isRefresh && smsCounter.date) {
        let substraction = Math.round(
          (new Date().getTime() - new Date(smsCounter.date).getTime()) / 1000,
        );
        if (substraction >= counterConfig) {
          startCountDownImmediate = false;
          repeatDisabled = false;
        } else {
          counter = counterConfig - substraction;
        }
      } else {
        smsCounter.isRefresh = true;
        new StorageHelper({
          id: process.env.VUE_APP_STORAGE_FIRST_LOGIN_SMS_COUNTER,
        }).set(smsCounter);
      }
    }
    return {
      formModel: {
        verificationCode: '',
        phoneNumber: '',
      },
      startCountDownImmediate: startCountDownImmediate,
      submitted: false,
      isRepeatButtonDisabled: repeatDisabled,
      timer: { set: counter, countdown: counter, reset: counterConfig },
      intervalID: '',
    };
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    routes() {
      return {
        auth: RoutesAthentication,
        login: RoutesLogin,
      };
    },
  },
  mounted() {
    const phoneNumberFromStorage = new StorageHelper({
      id: process.env.VUE_APP_STORAGE_CURRENT_USER_PN_KEY,
    }).get();

    this.formModel.phoneNumber = phoneNumberFromStorage;
    if (this.startCountDownImmediate) {
      this.startCountDown();
    }
  },
  destroyed() {
    if (this.intervalID) {
      clearInterval(this.intervalID);
    }
  },
  watch: {
    'timer.countdown': {
      handler: function(time) {
        if (time <= 0) {
          clearInterval(this.intervalID);
          this.isRepeatButtonDisabled = false;
          this.timer.set = this.timer.reset;
        }
      },
    },
  },
  methods: {
    startCountDown() {
      if (this.intervalID) {
        clearInterval(this.intervalID);
      }
      this.timer.countdown = this.timer.set;
      this.isRepeatButtonDisabled = true;
      this.intervalID = setInterval(() => {
        this.timer.countdown -= 1;
      }, 1000);
    },
    registerSmsCodeToStorage() {
      new StorageHelper({
        id: process.env.VUE_APP_STORAGE_CURRENT_SMS_KEY,
      }).set(this.formModel.verificationCode);
    },
    sendSmsCodeAgain() {
      Account.sendSmsForChangePassword(this.formModel.phoneNumber).then(res => {
        if (res.data.Data) {
          new StorageHelper({ id: process.env.VUE_APP_STORAGE_FIRST_LOGIN_SMS_COUNTER }).set({
            date: new Date(),
            isRefresh: true,
          });
          this.startCountDown();
        }
      });
    },
    async handleSubmit() {
      this.submitted = true;
      const isValid = await this.$refs.form.$refs.observer.validate();
      if (!isValid) {
        return;
      } else {
        Account.sendSmsCodeWithPhoneNumber(
          this.formModel.verificationCode,
          this.formModel.phoneNumber,
        ).then(res => {
          if (res.data.Data) {
            this.registerSmsCodeToStorage();
            const { Data } = res ? res.data : { Data: null };
            new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_PN_KEY }).set(
              this.formModel.phoneNumber,
            );
            new StorageHelper({ id: process.env.VUE_APP_STORAGE_TOKEN_KEY }).set(Data.token);
            this.$store.dispatch('auth/setAuthData', Data);
            this.$router.push('/auth/login/new-password');
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding-top: palette-space-level('60');
}

.small-header {
  padding-top: palette-space-level('20');
  padding-bottom: palette-space-level('10');
}

.phone-number {
  padding-top: palette-space-level('30');
  text-align: center;
  letter-spacing: 4px;
}

.verification-code-info {
  text-align: center;
  line-height: 1.5;
  max-width: 300px;
  padding-top: palette-space-level('10');
  padding-bottom: palette-space-level('10');
  margin: auto;
}

.form-text {
  padding-top: palette-space-level('20');
  padding-bottom: palette-space-level('10');
}

.button-holder {
  padding: palette-space-level('40') 0 palette-space-level('20');
  .submit-button {
    border-radius: palette-radius-level('2');
  }
}

.sms-button-wrapper {
  width: 140px;
  margin-left: auto;
  margin-right: auto;
  margin-top: palette-space-level(20);
  position: relative;
}

.disabled-sms-button-countdown {
  border: 1px solid palette-color-level('white', '100');
  // font-size: palette-font-size-level('4');
  // font-weight: palette-font-weight-level('5');
}

.sms-button-countdown {
  border: 1px solid palette-color-level('yellow', '30');
  // font-size: palette-font-size-level('4');
  // font-weight: palette-font-weight-level('5');
  cursor: pointer;
}

.sms-button-countdown,
.disabled-sms-button-countdown {
  border-radius: palette-radius-level(10);
  position: absolute;
  width: 35px;
  height: 35px;
  z-index: 50;
  text-align: center;
  padding-top: palette-space-level('8');
  margin-bottom: palette-space-level('2');
}

.brand-repeat-button {
  justify-content: center;
  padding: palette-padding-level(10, 20);
  border-radius: palette-radius-level(10);
  &-disabled {
    justify-content: flex-end !important;
  }
}
</style>
